<template>
  <v-dialog persistent v-model="warningShow" max-width="600" width="90%" overlay-color="white">
    <!-- 風險提示 -->
    <v-card class="d-flex flex-column justify-center align-center pa-md-10 pa-5 red-border">
      <h2 class="red--text rem-18">{{ $t('warning') }}</h2>
      <div class="rem-13 text-center">{{ $t(content) }}</div>
      <v-checkbox v-model="isConfirm">
        <template v-slot:label>
          <div class="primary--text">{{ $t('agreeRule') }}</div>
        </template>
      </v-checkbox>
      <btn :buttonText="'confirm'" :width="200" @clickBtn="confirm()"></btn>
    </v-card>
  </v-dialog>
</template>
<script>
import btn from '@/components/btn.vue'
export default {
  name: 'warning',
  props:{
    warningShow: {
      type: Boolean,
      default: false,
    },
    content: {
      type: String,
      default: '',
    },
  },
  data(){
    return {
      isConfirm: false
    }
  },
  components:{
    btn,
  },
  methods:{
    confirm(){
      if (this.isConfirm){
        this.$emit('confirm')
      }else{
        this.$toasted.error(this.$t('agreeFirst'))
      }
    },
  },
}
</script>