<template>
  <div class="store-pay d-flex justify-center">
    <div class="card-wrap d-flex flex-column align-center w-full">
      <pageTitle icon="icon-store-red" text="storePoints"></pageTitle>

      <infoBlock></infoBlock>

      <div class="store-wrap red-border rounded-lg pa-5 d-flex flex-column align-center">
        <div class="rem-md-13 rem-8">{{ $t('yourAccount') }}</div>
        <div class="red--text mb-5 rem-md-13 rem-8">{{ `0${$store.state.userInfo.phone_number}` }}</div>

        <div class="rem-md-13 rem-8">{{ $t('enterTBTAmount') }}</div>
        <div class="red--text mb-5 rem-md-13 rem-8">{{ $route.params.amount }}</div>

        <btn v-if="allowance===0 || allowance<balance" :buttonText="'approve'" @clickBtn="approve()"></btn>
        <btn v-else :buttonText="'store'" @clickBtn="store()"></btn>
      </div>
    </div>
    <loading :loadingShow="loadingShow" :content="loadingText"></loading>
    <warning :warningShow="warningShow" :content="'warningStore'" @confirm="warningShow=false"></warning>
  </div>
</template>
<script>
import base from '@/mixin/base'
import pageTitle from '@/components/pageTitle.vue'
import btn from '@/components/btn.vue'
import loading from '@/components/loading.vue'
import warning from '@/components/warning.vue'
import infoBlock from '@/components/infoBlock.vue'
import TBT from '@/plugins/tbt.js'
import TBTConsump from '@/plugins/tbtConsump.js'
export default {
  name: "Store",
  mixins: [base],
  data(){
    return {
      tbtContract: null,
      tbtConsumpContract: null,
      allowance: 0,
      balance: 0,
      loadingShow: false,
      loadingText: '',
      warningShow: true,
      timer: null,
      underBalance: (v) => parseInt(v) <= this.balance || 'Under Balance' 
    }
  },
  components:{
    pageTitle,
    btn,
    loading,
    warning,
    infoBlock
  },
  methods:{
    async store(){
      await this.connectMetamask()
      if (parseInt(this.$route.params.amount) <= this.balance){
        if (this.$store.getters.getIsCorrectChain){
          let result = await this.tbtConsumpContract.deposit(parseInt(this.$route.params.amount))
          // console.log('result', result)
          if (result.txHash){
            this.loadingText = 'waitStore'
            this.loadingShow = true
            let oldBalance = parseInt(this.$store.state.userInfo.balance)
            this.timer = window.setInterval(async () => {
              await this.$store.dispatch('getUserInfo')
              if (this.$store.state.userInfo.balance > oldBalance) {
                window.clearInterval(this.timer)
                this.loadingShow = false
                this.$router.push({name: "Store", params: {lang: this.$store.state.locale}})
              }
            }, 1000)
            this.$toasted.show(this.$t('txSend'))
          }else if (result.code === 4001){
            this.$toasted.error(this.$t('userRefuse'))
          }
        }else{
          this.$toasted.error(this.$t('changeMainnet'))
        }
      }else{
        this.$toasted.error(this.$t('underBalance'))
      }
    },
    async approve(){
      await this.connectMetamask()
      if (this.$store.getters.getIsCorrectChain){
        let result = await this.tbtContract.approve()
        // console.log('result', result)
        if (result.txHash){
          this.loadingText = 'waitApprove'
          this.loadingShow = true
          this.timer = window.setInterval(async () => {
            await this.getAllowance()
            if (this.allowance >= this.balance) {
              window.clearInterval(this.timer)
              this.loadingShow = false
            }
          }, 1000)
        }else if (result.code === 4001){
          this.$toasted.error(this.$t('userRefuse'))
        }
      }else{
        this.$toasted.error(this.$t('changeMainnet'))
      }
    },
    async getBalance(){
      try{
        this.balance = await this.tbtContract.getBalance(this.$store.state.userInfo.address)
      }catch(error){
        console.log('error', error)
      }
    },
    async getAllowance(){
      try{
        this.allowance = await this.tbtContract.getAllowance(this.$store.state.userInfo.address, this.$store.state.TBTConsumpAddress)
      }catch(error){
        console.log('error', error)
      }
    },
  },
  async mounted(){
    if (this.$route.params.amount){
      this.tbtContract = await new TBT()
      this.tbtConsumpContract = await new TBTConsump()
      await this.getBalance()
      await this.getAllowance()
    }else{
      this.$router.push({name: 'Store', params: {lang: this.$store.state.locale}})
    }
  }
}
</script>
<style lang="scss">
.store-pay{
  .store-wrap{
    width: 90%;
  }
}
</style>